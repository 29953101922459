.siteLayout {
  @apply mx-auto flex min-h-screen flex-col justify-between relative isolate overflow-hidden;
}

.siteHeader {
  @apply border-b sticky;
}

.siteHeader .siteHeaderBody {
  @apply lg:container lg:mx-auto px-4 py-3;
}

.siteMain {
  
}

.siteFooter {
  @apply border-t;
}

.siteFooter .siteFooterBody {
  @apply lg:container lg:mx-auto px-4 py-3;
}